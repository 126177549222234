.cs-menu-dropdown > li.ant-dropdown-menu-item {
  font-size: 13px;
  padding: 2px 10px;
}

.cs-menu-dropdown > li.ant-dropdown-menu-item:hover {
  background: #2b689c;
}

.cs-menu-dropdown > li.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover {
  background: #cccccc;
}

.job-board-v2 * {
  font-family: "Poppins", sans-serif;
}

.job-board-v2 .fa {
  font-family: "FontAwesome" !important;
}

.job-board-v2 .material-icons {
  font-family: "Material Icons" !important;
}

.cs-table-job-board.cs-table-job-board-v2 {
  border-collapse: separate;
  border-spacing: 0px 3px;
  margin-bottom: 0 !important;
  table-layout: fixed;
}

.cs-table-job-board.cs-table-job-board-v2 tbody td,
.cs-table-job-board.cs-table-job-board-v2 thead th {
  font-size: 12px !important;
  border-color: #000 !important;
  color: #362148;
  padding: 6px 6;
}

.cs-table-job-board.cs-table-job-board-v2 th {
  border-bottom: 1px solid !important;
  border-top: 0px;
  font-weight: 700;
  font-size: 13px;
}

.cs-table-job-board.cs-table-job-board-v2 tbody td {
  border-top: 0px solid #eaeaea !important;
  border-bottom: 1px solid #eaeaea !important;
  padding: 6px;
  vertical-align: middle;
}

.cs-table-job-board.cs-table-job-board-v2 td:first-child {
  border-left: 0px solid;
}

.cs-table-job-board.cs-table-job-board-v2 td:last-child {
  border-right: 0px solid;
}

.cs-table-job-board.cs-table-job-board-v2 tbody tr:last-child td {
  border-bottom: 0px !important;
}

.cs-collapse-v2.shift-updates-collapse .ant-collapse-header {
  color: #000 !important;
  font-weight: 400 !important;
  background: #eaeaea;
  font-weight: 400 !important;
  font-size: 13px;
  padding: 10px;
  border-radius: 10px !important;
}

.cs-collapse-v2.shift-updates-collapse .ant-collapse-item {
  margin-bottom: 10px !important;
  border: none;
}

.cs-collapse-v2.shift-updates-collapse.ant-collapse {
  border: none;
  background: transparent;
}

.cs-collapse-v2.shift-updates-collapse .ant-collapse-content-box {
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
}

.cs-collapse-v2.shift-updates-collapse .ant-collapse-content.ant-collapse-content-active {
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #eaeaea;
}

.cs-collapse-v2.shift-updates-collapse .ant-collapse-item-active .ant-collapse-header {
  border-radius: 10px 10px 0px 0px !important;
}

.job-range-picker-v2 .ant-input {
  border-color: #ddd !important;
  color: #000 !important;
  border-radius: 10px;
}

.job-range-picker-v2 .ant-input:hover {
  border-color: #ddd !important;
  color: #000 !important;
  border-radius: 10px;
}

.job-range-picker-v2 input::placeholder {
  color: #000 !important;
}

.job-range-picker-v2 input {
  padding: 4px 0px !important;
}

.job-range-picker-v2 .ant-calendar-picker-icon {
  color: #36214880 !important;
}

.job-range-picker-v2 .ant-input:focus {
  box-shadow: none !important;
}

.job-v2-date-picker {
  width: 100% !important;
  background-color: #eaeaea !important;
  border-radius: 8px !important;
}

.job-v2-date-picker .ccalnd-icon {
  top: 50% !important;
  margin: 0px !important;
  color: #362148 !important;
  transform: translateY(-50%) !important;
  z-index: 0;
}

.job-v2-date-picker .ant-input {
  background-color: #eaeaea;
  height: 34px;
  border: none !important;
  border-radius: 8px !important;
  font-size: 12px;
}

.job-v2-date-picker .ant-input:focus {
  box-shadow: 0 0 0 0.05rem #c0b4ff !important;
  border: 1px solid #ddd !important;
}

.job-v2-date-picker .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  box-shadow: 0 0 0 0.05rem #c0b4ff !important;
}

.job-v2-date-picker.ant-calendar-picker:focus {
  box-shadow: 0 0 0 0.05rem #c0b4ff !important;
  border: 1px solid #ddd !important;
}

.job-v2-date-picker .ant-calendar-picker-clear {
  background-color: #eaeaea;
}

.job-v2-date-picker .anticon-close-circle.ant-calendar-picker-clear {
  background: transparent;
  right: 27px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  width: auto;
}

.custom-section-v2-collapse.ant-collapse {
  background: #fff;
  padding: 0px;
  border: none !important;
}

.custom-section-v2-collapse .ant-collapse-item {
  border: 1px solid #eaeaea !important;
  border-radius: 9px !important;
  padding: 0px !important;
}

.custom-section-v2-collapse .ant-collapse-item .ant-collapse-header {
  padding: 0px !important;
  border-radius: 8px !important;
}

.custom-section-v2-collapse .ant-collapse-item .custom-section-v2-panel-heading {
  border-radius: 8px !important;
  font-weight: 600;
  font-size: 13px;
  color: #362148;
}

.custom-section-v2-collapse .ant-collapse-item-active .custom-section-v2-panel-heading {
  border-radius: 8px 8px 0px 0px !important;
}

.custom-section-v2-collapse .cs-select-v2 span {
  font-weight: normal;
  font-size: 13px;
  font-weight: 400;
  color: #362148;
}

.job-board-v2-panel-part .ant-collapse-header {
  padding: 10px 5px !important;
}

.ant-collapse-item.job-board-panel-part.job-board-v2-panel-part {
  /* border-bottom: none !important;
  border-left: none !important;
  border-right: none !important; */
}

.ant-collapse-item.job-board-custom-panel,
.ant-collapse-item.job-board-custom-panel > .ant-collapse-header {
  border-left: none !important;
  border-right: none !important;
}

.job-board-status-outsourced {
  background: #5cbbff !important;
  color: #fff !important;
}

.job-board-status-started {
  background: #7ed321 !important;
  color: #fff !important;
}

.job-board-status-booked {
  background: #45b0e1 !important;
  color: #fff !important;
}

.job-board-status-inprogress {
  background: #ffc000 !important;
  color: #fff !important;
}

.job-board-status-allocated {
  background: #4a90e2 !important;
  color: #fff !important;
}

.job-board-status-completed {
  background: #92d050 !important;
  color: #fff !important;
}

.job-board-status-signedoff {
  background: #7b1fa2 !important;
  color: #fff !important;
}

.job-board-status-invoiced {
  background: #ee82ee !important;
  color: #fff !important;
}

.job-board-status-cancelled {
  background: #d0021b !important;
  color: #fff !important;
}

.job-v2-container * {
  font-family: "Poppins", sans-serif;
}

.job-v2-date-picker,
.job-v2-time-picker {
  width: 100% !important;
  background-color: #eaeaea !important;
  border-radius: 8px !important;
}

.job-v2-date-picker .ant-input,
.job-v2-time-picker .ant-time-picker-input {
  background-color: #eaeaea;
  height: 34px;
  border: 1px solid #ddd;
  border-radius: 8px !important;
  font-size: 12px;
}

.job-v2-date-picker .ant-input:focus,
.job-v2-time-picker .ant-time-picker-input:focus {
  box-shadow: 0 0 0 0.05rem #c0b4ff;
  border: 1px solid #ddd;
}

.job-v2-date-picker.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #ddd !important;
}

.job-v2-date-picker .ccalnd-icon,
.job-v2-time-picker .ant-time-picker-icon {
  top: 50% !important;
  margin: 0px !important;
  color: #c0b4ff !important;
  transform: translateY(-50%) !important;
  z-index: 0;
}

.job-v2-time-picker .ant-time-picker-clock-icon {
  color: #c0b4ff !important;
}

.job-v2-date-picker .ant-calendar-picker-clear,
.job-v2-time-picker .ant-time-picker-clear {
  background-color: #eaeaea;
  right: 30px;
  width: auto;
  background: transparent !important;
}

.job-upload-v2 {
  display: block;
  width: inherit;
}

.job-upload-v2 .ant-upload.ant-upload-select.ant-upload-select-text {
  width: 100%;
}

.job-swms-container *,
.job-swms-modal-container * {
  font-family: "Poppins", sans-serif;
  text-align: initial;
}

.job-swms-container .fa,
.job-swms-modal-container .fa {
  font-family: FontAwesome !important;
}

.job-swms-container {
  font-size: 13px;
}

.cs-table-job-swms tbody td,
.cs-table-job-swms thead th {
  padding: 4px !important;
}

.cs-table-job-swms tbody tr:last-child td {
  border-bottom: 0px !important;
}

.cs-table-job-swms .swms-table-child tbody td {
  border-top: 0px solid #eaeaea !important;
  border-bottom: 1px solid #eaeaea !important;
  vertical-align: middle;
}

.cs-table-job-swms .swms-table-child tbody tr:last-child td {
  border-bottom: 0px !important;
}

.custom-job-swms-collapse-panel .ant-collapse-content {
  color: #000;
}

.custom-job-swms-collapse-panel .ant-collapse-content-box {
  padding: 0px 10px !important;
}

.custom-job-swms-collapse-panel {
  margin-bottom: 10px;
}

.previewSwmsMain .form-group label {
  max-width: 110px;
  width: 100%;
  background: #105a92;
  text-align: right;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  padding-right: 5px;
  padding-left: 3px;
  margin: 0%;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: end;
}

.form-group label.half-width {
  min-width: 266px;
  max-width: 100%;
  width: fit-content;
  justify-content: center;
}

.form-check {
  text-align: left;
}

.box-check ul li .form-check label {
  all: inherit;
  padding: 0;
  /*cursor: pointer;*/
}

.h2_risk-matrix {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.form-group input {
  height: 22px;
  border-radius: 0;
  font-size: 12px;
  padding: 0;
  padding-left: 2px;
}

.form-group textarea {
  border-radius: 0;
  font-size: 12px;
}

.form-group input[readonly],
.form-group textarea[readonly] {
  background-color: #fff;
  opacity: 1;
  margin-top: 0px;
}

.form-group input:focus,
.form-group textarea:focus {
  box-shadow: none;
}

.form-group input[type="checkbox"][readonly] {
  pointer-events: none;
  height: auto;
  width: auto;
}

.form-group.flexlabel label.role {
  max-width: 68px;
}

.sign-history-table-v2 {
  border-collapse: initial;
  border-spacing: 0 3px;
  margin-bottom: 0 !important;
  table-layout: fixed;
  height: auto;
  width: -webkit-fill-available;
}

.job-preview-table .cs-table tbody td {
  border: 0px !important;
}

.job-preview-table .cs-table {
  border-spacing: 0px !important;
}

.job-preview-table .cs-table .job-preview-row > td {
  border-top: 1px solid #000 !important;
}

.job-icons-alignment {
  display: flex;
  align-items: center;
  justify-content: end;
  grid-column-gap: 3px;
  column-gap: 3px;
}

.job-preview-icons {
  font-size: 13px !important;
  color: #212529 !important;
}

.job-board-default-panel-cursor .ant-collapse-header {
  cursor: auto !important;
}

.shifts-list-checkbox-group .ant-checkbox-wrapper {
  font-size: 14px;
  color: #212529;
}

.shifts-list-checkbox-group .ant-checkbox-wrapper:hover {
  /* color: #212529; */
  color: black;
  /* Change text color on hover */
}

.shifts-list-checkbox-group .ant-checkbox-wrapper span {
  margin-left: 8px;
  /* Adjust spacing between checkbox and label */
}

.shifts-list-checkbox-group .ant-checkbox-wrapper .ant-checkbox-checked + span {
  font-weight: bold;
  /* Bold text when checked */
  /* color: #212529;
   */
  color: #362148;
  /* Change color when checked */
}

.shifts-list-check-all {
  font-size: 14px;
  color: #212529;
}

.shifts-list-check-all:hover {
  /* color: #212529; */
  color: black;
  /* Change text color on hover */
}

.shifts-list-check-all span {
  margin-left: 8px;
  /* Adjust spacing between checkbox and label */
}

.shifts-list-check-all .ant-checkbox-checked + span {
  font-weight: bold;
  /* Bold text when checked */
  /* color: #212529;
   */
  color: #362148;
  /* Change color when checked */
}

table.job-board-freq-table thead th {
  color: #362148;
}

.freq-note-textarea,
.freq-note-textarea:disabled {
  flex: 1;
  color: #362148 !important;
  font-size: 13px !important;
  padding: 6px 10px;
}

.freq-task-conditions {
  min-height: 80px;
  background: #eaeaea;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 13px;
  width: 100%;
  padding: 6px 10px;
  resize: none;
  color: #362148;
  overflow: hidden auto;
  max-height: 160px;
}

.freq-task-conditions p {
  font-weight: 400;
  margin: 0px;
}

.freq-task-conditions ul,
.freq-task-conditions ol {
  margin: 0 0 0 20px;
  font-weight: 400 !important;
}

.freq-task-conditions ul > li {
  list-style: disc !important;
}

.freq-task-conditions ol > li {
  list-style: auto !important;
}

.worlflow-history-sidedrawer .ant-drawer-content-wrapper{
  box-shadow: none !important;
}

.worlflow-history-sidedrawer .ant-drawer-content {
  border-radius: 0px 0px 28px 0px;
}