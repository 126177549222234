.Title {
  font-size: 18px;
  color: #2b689c !important;
  font-weight: bold;
}

.Heading {
  font-size: 16px;
  color: #2b689c !important;
  font-weight: bold;
}

.HeaderContainer {
  padding: 10px;
  margin: 0px;
  background: #fff;
  text-align: left;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.blankContainer {
  background: #fff;
  text-align: initial;
}

.bordered {
  border: 1px solid #ccc !important;
}

.shadowed {
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px 0px #ccc;
}

.NewTaskBorder {
  border-bottom: 1px solid #2b689c !important;
}

.SingleLine {
  height: 35px;
  font-size: 13px;
  padding: 7px 9px;
  color: #362148;
  background: #eaeaea;
  border-radius: 10px;
  outline: none;
  border: none;
  width: -webkit-fill-available;
}

.SingleOutlined {
  border: 1px solid #ccc !important;
  border-radius: 3px;
  padding: 1px 2px;
  height: 22px;
  color: #000;
  outline: none;
  width: -webkit-fill-available;
}

.SingleOutlined:hover {
  border: 1px solid #ccc !important;
  box-shadow: none;
  outline: none;
}

.SingleLine:hover {
  border-width: none !important;
  border-right-width: 0px !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #16abff;
  box-shadow: none;
  outline: none;
}

.SingleLine::placeholder {
  color: #999;
}

.SingleLine:focus {
  box-shadow: none !important;
  border-right-width: 0px !important;
  outline: none;
  border-bottom-color: #16abff;
}

.SingleOutlined:focus {
  border: 1px solid #16abff !important;
  box-shadow: none !important;
  outline: none;
}

.card {
  padding: 5px 10px;
  border: 1px solid rgba(54, 33, 72, 0.3);
  border-radius: 8px;
}

.Label {
  font-size: 12px;
  color: #362148;
  font-weight: bold;
}

.iconButton {
  color: #fff;
  padding: 4px;
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
}

.greenButton {
  background-color: #90bf00 !important;
}

.redButton {
  background-color: red !important;
}

.textButton {
  background-color: #16abff;
  border: none;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  padding: 4px 6px;
  height: auto;
  cursor: pointer;
  line-height: 1;
}

.largeButton {
  background-color: #16abff;
  border: none;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
  height: auto;
}

.BlueButton_dark {
  background-color: #16abff;
  color: #fff;
}

.RedButton_dark {
  background-color: red;
  color: #fff;
}

.ModalCloseIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.TaskHeadRow {
  background-color: transparent;
  padding: 5px;
  border-bottom: 1px solid rgba(54, 33, 72, 0.3);
  color: #000 !important;
  text-align: start;
}

.Panel {
  border: 1px solid #000 !important;
  /* border-bottom: none !important; */
  margin: 10px 0px !important;
  text-align: start;
}

.PanelV2 {
  border: 1px solid #000 !important;
  margin-bottom: 20px !important;
  text-align: start;
}

.PartPanel {
  border: 1px solid #000 !important;
  border-top: none !important;
  margin-bottom: 20px;
}

.blueText {
  color: #2b689c !important;
}

.TaskHeader {
  color: #000;
  font-weight: initial;
  line-height: 1.1;
}

.lightBorder {
  border: 1px solid #c4e1ec;
}

.lightBox {
  border: 1px solid #c4e1ec;
  background: #f7fcfe;
  color: #000;
}

.greyHR {
  height: 2px;
  border-width: 0;
  color: #8080803b;
  background-color: #8080803b;
  margin: 0px;
}

.SingleSelect {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
}

.SingleSelect:hover {
  border-bottom-color: #2b689c;
}

.JobTab {
  display: flex;
  flex-direction: column;
  /* padding: 5px 10px; */
  line-height: 1.5 !important;
  align-items: center;
  font-size: 13px;
  padding: 10px 10px;
  min-width: 100px;
  justify-content: center;
  height: 90px;
  padding: 0px;
}

.CalendarFloatIcon {
  position: absolute;
  z-index: 15;
  right: 0;
  background: #fc5d83;
  padding: 0px 15px;
  border-radius: 2px;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
  box-shadow: 0px 1px 2px 1px #eee;
}

.transparentIcon {
  color: transparent !important;
  background: transparent !important;
}

.SmallSaveIcon {
  background: rgb(22, 171, 255);
  color: rgb(255, 255, 255);
  border-radius: 3px;
  padding: 3px 5px;
  cursor: pointer;
  box-shadow: 0px 1px 3px 0px #ccc;
  height: auto;
}

.lightGreyTopBorder {
  border-top: 2px solid #8080803b;
}

.HideXOverflow {
  overflow-x: hidden;
}

.jobStatusBox {
  background: #f5f5f5;
  /* border: 1px solid #d9d9d9; */
  border-radius: 60px;
  padding: 5px 15px;
  margin-left: 3px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  width: 100%;
  font-size: 13px;
  text-align: center;
}

.redLineThrough {
  text-decoration: line-through red 3px;
}

.disabledButton {
  color: #fff;
  background-color: #ccc;
  cursor: not-allowed;
}

.PlusBtn {
  color: #53cb8d;
  font-size: 13px;
}

.MinusBtn {
  color: red;
  font-size: 25px;
}

.IconBtn {
  padding: 0;
  margin: 0;
  height: auto;
  background: none;
}

.SimpleAddBtn {
  background: none;
  height: auto;
  color: #53cb8d;
  font-size: 13px;
  font-weight: 700;
  padding: 0;
}

.SimpleCancelBtn {
  background: none;
  height: auto;
  color: red;
  font-size: 13px;
  font-weight: 700;
  padding: 0;
}

.DisabledBtn {
  background-color: #ccc !important;
}

.DisabledText {
  color: #ccc !important;
}

.OutsourceBtn {
  background-color: #16aaff;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 2px 10px;
  height: auto;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
}

.Collapse {
  background-color: #fff !important;
}

.ContractorsPanelContainer {
  padding-right: 10px;
  padding-left: 10px;
}

.orangeButton {
  background-color: #ff9800;
}

.InvoiceText {
  font-size: 13px !important;
  font-weight: normal !important;
  line-height: 1.2;
}

.BlueTextButton {
  background: none;
  border: none;
  color: #135f9b;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.newContainer {
  padding: 16px;
  background: #eaeaea;
  height: 100%;
  border-radius: 45px;
}

.PrimaryText {
  color: #362148 !important;
  font-family: "Poppins", sans-serif !important;
}

.PrimaryButton {
  font-size: 14px;
  height: auto;
  color: #362148;
  background: #c0b4ff;
  border: 0;
  padding: 8px 25px;
  border-radius: 50px;
  font-weight: normal;
}

.PrimaryButton:disabled {
  background: #ccc !important;
  color: #000 !important;
  border-color: #ccc !important;
  cursor: not-allowed;
}

.PrimaryButton:hover,
.PrimaryButton:focus {
  color: #fff;
  background-color: #362148;
  border-color: #362148;
}

.PageHeading {
  font-size: 20px;
  color: #362148;
  font-weight: 600;
}

.JobTabIcon {
  height: 24px;
  max-width: 28px;
  margin-bottom: 10px;
}

.TaskListContainer {
  border: 1px solid rgba(54, 33, 72, 0.3);
  border-radius: 10px;
}

.JobListHeading {
  color: #362148;
  font-weight: 600;
}

.JobListHeadingIcon {
  color: #362148;
  font-size: 14px;
}

.SectionHeading {
  font-size: 14px !important;
  color: #362148 !important;
  font-weight: bold;
}

.FilterResetButton {
  background: none;
  border: none;
  color: red;
  text-decoration: underline !important;
  font-size: 13px;
  font-weight: normal;
}

.FilterResetButton:hover {
  color: red !important;
}

.FilterResetButton span {
  color: inherit !important;
  text-decoration: inherit;
}

.PrimaryButton span {
  color: inherit !important;
}

.CommonLabel {
  font-size: 12px;
  color: #362148;
}

.InputFieldV2 {
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #ddd;
  color: #000;
}

.InputFieldV2::placeholder {
  color: #362148 !important;
}

.InputFieldV2:focus,
.InputFieldV2:focus-visible,
.InputFieldV2:focus-within {
  box-shadow: 0 0 0 0.05rem #c0b4ff;
  border: 1px solid #ddd;
  outline: none;
}

.InputFieldV2:hover {
  box-shadow: none;
  border: 1px solid #ddd;
}

.InputFormField {
  background: #eaeaea;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 34px !important;
}

.InputFormField:disabled {
  color: #999999 !important;
}

.BackgroundSectionHeading {
  padding: 8px 8px 8px 16px;
  border-radius: 8px 8px 0px 0px;
  background-color: #eaeaea;
  font-size: 13px;
  font-weight: bold;
  color: #362148;
  border: 1px solid #eaeaea;
}

.SecondaryButton {
  background-color: #362148;
  color: #90bf00 !important;
  border-radius: 50px;
  padding: 5px 25px;
  font-size: 14px;
  border: 1px solid #362148;
  font-weight: 500;
  line-height: 1;
  font-family: "Poppins", sans-serif !important;
}

.SecondaryButton:hover {
  background-color: initial;
  color: #362148 !important;
  border: 1px solid #362148;
}

.SecondaryButton:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  color: #eee !important;
}

.SmallPrimaryButton {
  font-size: 13px;
  padding: 5px 15px;
}

.MuiIconButton {
  padding: 0px !important;
  height: auto;
}

.GreenTextV2 {
  color: #9abe3a !important;
}

.OpenBodyBorder {
  border: 1px solid #eaeaea;
  border-radius: 0px 0px 8px 8px;
  border-top: 0px;
}

.PrimaryTextButton {
  background: transparent;
  color: #c0b4ff;
}

.ReportCard {
  background: #f0efeecc;
  border-radius: 8px;
  padding: 10px;
  margin: 5px 0px;
}

.ReportImage {
  height: inherit;
  width: inherit;
  object-fit: contain;
  cursor: pointer;
  padding: 5px;
}

.ReportTextArea {
  resize: none;
  background: #fff;
  color: #36214880;
  font-size: 13px;
  padding: 6px;
  border-radius: 6px;
  width: 265px;
  border: 1px solid #eaeaea;
}

.ReportTextArea:focus {
  border-color: #c0b4ff;
}

.ReportImageContainer {
  background: #fafafa;
  border: 1px solid #362148;
  border-radius: 8px;
  display: flex;
  /* flex: 0 0 30%; */
  height: 100%;
  max-height: 100px;
  width: 100%;
}

.TabContainer {
  color: #000;
  padding: 2px;
}

.BackButton {
  background: none;
  border: none;
  color: #362148;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.FilterTextField {
  border: 1px solid #dddddd;
  background: #ffffff;
  font-size: 13px;
  height: 32px;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  color: #333333;
  outline: 0px;
}

.FilterTextField::placeholder {
  color: #362148;
}

.FilterTextField:focus,
.FilterTextField:hover {
  -webkit-box-shadow: 0 0 0 0.05rem #c0b4ff !important;
  box-shadow: 0 0 0 0.05rem #c0b4ff !important;
  border: 1px solid #dddddd;
}

.TextField {
  background: #eaeaea;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
  font-family: "Poppins", sans-serif;
  color: #000;
}

.TextArea {
  background: #eaeaea;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  padding: 5px;
  resize: none;
  color: #000;
}

.TextField:disabled,
.TextArea:disabled {
  color: #999999 !important;
}

.TextField:focus,
.TextArea:focus {
  -webkit-box-shadow: 0 0 0 0.05rem #c0b4ff !important;
  box-shadow: 0 0 0 0.05rem #c0b4ff !important;
}

.TableText {
  font-family: "Poppins", sans-serif !important;
  font-size: 13px !important;
}

.SwmsTitle {
  font-size: 13px;
  color: #000 !important;
  user-select: none;
  font-weight: 700;
}

.TabsCover {
  border: 1px solid #3621484d;
  padding: 6px;
  border-radius: 8px;
}

.SwmsTaskCollpase {
  padding: 10px 10px 10px 16px;
}

.SwmsPanelBack {
  background-color: #eaeaea;
}

.ModalHeading {
  font-size: 14px !important;
  font-weight: bold;
  color: #362148;
}

.PrimaryButton {
  background-color: #c0b4ff;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
  font-family: "Poppins", sans-serif !important;
}

.PrimaryButton:hover,
.PrimaryButton:focus {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.PrimaryButton:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  color: #eee !important;
}

.OutlinedButton {
  background-color: initial;
  border: none;
  color: #362148 !important;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
}

.OutlinedButton:disabled {
  cursor: not-allowed;
  color: #eee !important;
}

.SplitColumns {
  columns: 2 !important;
  -webkit-columns: 2 !important;
  -moz-columns: 2 !important;
}

.ErrorButton {
  background-color: #ff0000;
  color: #fff !important;
}

.ErrorButton:hover,
.ErrorButton:focus {
  background-color: #b60000;
  color: #fff !important;
}

.MinusIcon {
  font-size: 16px;
  height: 16px;
  color: red;
}

.IconBtn {
  height: auto;
  padding: 0px !important;
}

.SuccessIcon {
  color: #90bf00 !important;
}

.PrimaryIcon {
  color: #c0b4ff !important;
}

.SecondaryIcon {
  color: #362148 !important;
}

.DarkIcon {
  color: #000 !important;
}

.WarningIcon {
  color: #ed6c02 !important;
}

.DangerIcon {
  color: #f44336 !important;
}

.IconBtn:disabled {
  color: #00000042 !important;
  cursor: not-allowed;
}

.RiskAssementTable > thead > tr > th {
  text-align: center !important;
  vertical-align: middle !important;
}

.RiskAssementTable td,
.RiskAssementTable th {
  padding: 5px;
  border: 1px solid #000 !important;
  vertical-align: middle !important;
  text-align: center !important;
  background-color: #fff;
  font-weight: bold;
}

.DragIcon {
  color: #332246;
  width: 16px;
}

.HazardBox {
  border: 1px solid #ccc;
  padding: 10px 0px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.UploadBox {
  display: flex;
  padding: 4px;
  border-radius: 3px;
  align-items: center;
}

.UploadImageWrapper {
  height: 40px;
  width: 40px;
  flex: 0 0 40px;
}

.UploadImage {
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.UploadNameWrapper {
  flex: 1;
  min-width: 0;
}

.UploadName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AttachemntIcons {
  margin-left: auto;
  flex: 0 0 32px;
}

.UploadErrorBox {
  padding-left: 10px;
  color: red;
  border-top: 1px solid #f2f2f2;
}

.UploadPhoto {
  max-height: 40px;
  max-width: 40px;
  object-fit: scale-down;
  margin: auto;
  display: block;
}

.SwmsPreviewTable {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 13px;
}

.SwmsPreviewTable > thead > tr > th {
  vertical-align: middle !important;
}

.JSATable > thead > tr > th {
  background-color: #002060 !important;
  color: #fff !important;
}

.SwmsPreviewTable td,
.SwmsPreviewTable th {
  padding: 5px;
  border: 1.5px solid #2aa3dd !important;
  vertical-align: top !important;
  background-color: #fff;
}

.SwmsPreviewTable th {
  font-weight: bold;
}

.SwmsPreviewLink,
.SwmsPreviewLink:hover,
.SwmsPreviewLink:focus {
  color: red !important;
  /* font-size: 12px; */
  text-decoration: underline;
}

.SwmsPreviewSubHeading {
  font-size: 13px;
  font-weight: bold;
}

.SignedIndicator {
  background-color: #90bf00;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.UnsignedIndicator {
  background-color: #f44336;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.SignIndicatorBox {
  height: 18px;
  width: 18px;
  margin: 0px auto;
  border-radius: 50%;
  border: 1px solid #362148;
}

.SignIndicatorHeader {
  min-width: 90px;
  width: auto;
  max-width: 100%;
}

.SmallCol {
  min-width: 90px;
  width: 90px;
  position: sticky;
  background: #fff;
  left: 0px;
  max-width: 90px;
}

.SmallCol2 {
  left: 90px;
}

.MidCol {
  min-width: 250px;
  width: 250px;
  position: sticky;
  left: 180px;
  max-width: 250px;
  background: #fff;
  box-shadow: 1px 0px 0px 0px #ccc;
}

.Link,
.Link:hover,
.Link:focus {
  color: #3273f6;
  text-decoration: underline;
}

.RiskControl {
  box-shadow: 1px 0 0 0 #000, 0 1px 0 0 #000, 1px 1px 0 0 #000, 1px 0 0 0 #000 inset, 0 1px 0 0 #000 inset;
}

.StatusBox {
  border-radius: 25px;
  padding: 5px 35px;
  text-align: center;
  max-width: 130px;
  width: auto;
  height: auto;
  font-size: inherit;
  color: #000;
  margin: auto;
}

.PreviewHeaderTxt {
  font-weight: bold;
  font-size: 15px !important;
  margin-bottom: 0px !important;
}

.PreviewHeader {
  font-weight: bold;
  margin-bottom: 0px !important;
}

.PreviewBorder {
  background-color: #595959 !important;
  border-color: #595959 !important;
}

.JobPreviewContent {
  margin-bottom: 0px !important;
  font-weight: normal;
}

.PriviewTableHeader {
  font-size: 12px;
  font-weight: bold;
  background-color: #dae8fc;
  padding: 2px 5px;
}

.PreviewTableHeadContent {
  font-weight: bold !important;
}

.NoSpace {
  margin-bottom: 0px !important;
}

.JobPreviewLogo {
  max-height: 70px;
  max-width: 200px;
}

.PreviewLogoWrapper {
  height: 70px;
  /* display: flex; */
  align-items: center;
  justify-content: end;
}

.PreviewContainer {
  color: #000;
}

.JobPreviewRow {
  background-color: #f5f5f5 !important;
}

.JobsTableHeader {
  background: #eef9ff;
  border: none;
}

.OverlayBox {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255 255 255 / 40%);
  z-index: 2000;
  border-radius: 10px;
}

.OverlayedContent {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.QuoteEmailHead {
  color: #362148;
  font-weight: 800;
  font-size: 16px;
}

.ModalNextIcon {
  background-color: #362148;
  color: #90bf00 !important;
  border-radius: 50%;
  padding: 5px !important;
  height: 30px;
}

.BtnNextTab {
  background-color: #362148;
  color: #90bf00 !important;
  border-radius: 50px;
  padding: 5px 25px;
  font-size: 14px;
  border: 0;
  border: 1px solid #362148;
  font-weight: 500;
  line-height: 1;
}

.BtnNextTab:hover {
  background-color: transparent;
  color: #362148 !important;
  border: 1px solid #362148;
}

.RfqMailFormLabel {
  font-size: 12px;
  color: #362148;
  font-weight: bold;
}

.RfqFormField {
  background: #eaeaea;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}

.RfqFormField:disabled {
  color: #999999 !important;
}

.InvoiceStatusBox {
  border-radius: 25px;
  padding: 5px 0;
  text-align: center;
  max-width: 130px;
  width: 100% !important;
  height: auto;
  font-size: inherit;
  color: #000;
  margin: auto;
}

.JobTasksListContainer {
  border-radius: 10px;
}

.JobTaskHeadRow {
  background-color: transparent;
  padding: 5px;
  border-bottom: 1px solid rgba(54, 33, 72, 0.3);
  color: #000 !important;
  text-align: start;
  border: 1px solid #000;
  border-bottom: 0px !important;
}

.JobPurpleButton {
  font-size: 14px;
  color: #90bf00;
  background: #362148;
  border: 1px solid #362148;
  border-radius: 50px;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
  font-family: "Poppins", sans-serif !important;
  padding: 9px 25px;
}

.JobPurpleButton:disabled {
  background: #ccc !important;
  color: #eee !important;
  border-color: #ccc !important;
  cursor: not-allowed;
}

.JobPurpleButton:hover,
.JobPurpleButton:focus {
  color: #362148;
  background-color: initial;
}

.disabledJobTab {
  color: #ccc !important;
  cursor: not-allowed !important;
}

.DetailsDivider {
  background: #f0efee;
  height: 1px;
  opacity: 1;
}

.FrequencyPanelItem {
  margin-bottom: 10px !important;
  border-radius: 4px !important;
  border: 1px solid #3621484d !important;
}

.BtnFilterTab {
  background-color: #c0b4ff;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 12px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
}

.BtnResetTab {
  background-color: transparent;
  border: none;
  color: #362148 !important;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-size: 12px;
}

.BtnFilterTab:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.SwmsPreviewLinkImg,
.SwmsPreviewLinkImg:hover,
.SwmsPreviewLinkImg:focus {
  color: #2aa3dd !important;
  font-size: 12px;
  font-weight: bold;
}

.DragIcon {
  color: #332246 !important;
  width: 16px;
}

.JobReportPreviewFooter {
  flex: 0;
  background: #fff;
  text-align: right;
  padding: 10px 10px 0 0;
}

.JobReportPreviewSelected {
  background-color: #2aa3dd;
}

.JobReportPreviewTextBox {
  background-color: #d7effa;
  min-height: 100px;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden auto;
  color: #000;
}

.JobReportPreviewTextarea {
  width: -webkit-fill-available;
  width: -moz-available;
  height: auto !important;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 6px 10px;
  resize: none;
  outline: none;
}

.JobReportPreviewTextarea:hover,
.JobReportPreviewTextarea:focus {
  outline: none;
  border-color: #16abff !important;
  box-shadow: none;
}

.ReportPreviewButton {
  background: none;
  border: none;
  color: #999;
}

.ReportClearIconButton {
  color: #999;
}

.ReportClearIconButton:hover {
  color: red;
}
